import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IActivityRate } from '../../../@types/model/masterData/activityRate/activityRate';
import { IActivityRateUpsert } from '../../../@types/model/masterData/activityRate/activityRateUpsert';
import qs from 'qs';

export default class ActivityRateHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataActivityRateGetList(divisionIds : Array<number>) : AxiosPromise<Array<IActivityRate>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/ActivityRate/GetList`, {
            params: {
                divisionIds,
            },
            paramsSerializer: qs.stringify,
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static activityRateUpsert = (activityRateUpsert : IActivityRateUpsert, type : 'Add' | 'Edit') : AxiosPromise<IActivityRate> => {
        return axios.post(`${API_URL}/v1/MasterData/ActivityRate/${type}`, activityRateUpsert);
    }

    public static activityRateDelete = (activityRateId : number) : AxiosPromise<IActivityRate> => {
        return axios.delete(`${API_URL}/v1/MasterData/ActivityRate/Delete`, {
            params: { activityRateId },
        });
    }
}