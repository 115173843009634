import { createReducer } from '@reduxjs/toolkit';
import { IJob } from '../../@types/model/job/job';
import JobActions from './actions';

export interface IJobState {
    isLoading : boolean;
    jobList : Array<IJob> | null;
}

const initialState = {
    isLoading: false,
    jobList: null,
};

const jobReducer = createReducer<IJobState>(initialState, (builder) => {
    builder.addCase(JobActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });

    builder.addCase(JobActions.setJobData, (state, action) => {
        state.jobList = action.payload;
    });
});

export default jobReducer;