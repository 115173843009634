import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IUser } from '../../../@types/model/auth/user/user';
import { IUserUpsert } from '../../../@types/model/auth/user/userUpsert';
import { IUserToken } from '../../../@types/model/auth/userToken/userToken';
import { IGoogleLogInView } from '../../../@types/model/auth/logIn/GoogleLogInView';

export default class UserHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;
    public static cancelGetUser ?: Canceler;

    public static userGetList(ignoreRights : boolean | null, isActive : boolean | null) : AxiosPromise<Array<IUser>> {
        if (this.cancelGetList) {
            this.cancelGetList();
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/Right/User/GetList`, {
            params: {
                ignoreRights,
                isActive
            },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static getUser() : AxiosPromise<IUser> {
        if (this.cancelGetUser) {
            this.cancelGetUser();
            this.cancelGetUser = undefined;
        }

        return axios.get(`${API_URL}/v1/Right/User/Get`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetUser = c;
            }),
        });
    }

    public static setEmployeeNumber = (employeeNumber : string) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/v1/Right/User/SetEmployeeNumberr`, null, {
            params: {
                employeeNumber: employeeNumber,
            },
        });
    }

    public static setPassword = (password : string) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/v1/Right/User/UpdatePassword`, null, {
            params: {
                password,
            },
        });
    }

    public static userUpsert = (upsertData : Array<IUserUpsert>, type : 'Add' | 'Edit') : AxiosPromise<Array<IUser>> => {
        return axios.post(`${API_URL}/v1/Right/User/${type}`, upsertData);
    }

    public static userDelete = (userId : number) : AxiosPromise => {
        return axios.delete(`${API_URL}/v1/Right/User/Delete`, {
            params: { userId },
        });
    }

    public static linkGoogleAccount = (googleLogInView : IGoogleLogInView) : AxiosPromise<IUser | null> => {
        return axios.post(`${API_URL}/v1/Right/User/LinkGoogleAccountToUser`, googleLogInView);
    }

    public static unlinkGoogleAccount = (userIds : Array<number>) : AxiosPromise<IUser | null> => {
        return axios.post(`${API_URL}/v1/Right/User/UnlinkGoogleAccountFromUser`, userIds);
    }
}
