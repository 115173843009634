import { createReducer } from '@reduxjs/toolkit';
import { IPayRunExtraction } from '../../@types/model/payRunExtractions/payRunExtraction';
import PayRunExtractionActions from './actions';

export interface IPayRunExtractionState {
    isLoading : boolean;
    payRunExtractionList : Array<IPayRunExtraction> | null;
}

const initialState = {
    isLoading: false,
    payRunExtractionList: null,
};

const payRunExtractionReducer = createReducer<IPayRunExtractionState>(initialState, (builder) => {
    builder.addCase(PayRunExtractionActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(PayRunExtractionActions.setPayRunExtractionData, (state, action) => {
        state.payRunExtractionList = action.payload;
    });
});

export default payRunExtractionReducer;