import React, { useEffect, useMemo, useState } from 'react';
import { MuiAutocompleteSelect, ProjectDialog, PillButton, ConfirmationDialog, CustomTooltip, GoogleSignInButton, IGoogleLoginError, IGoogleLoginSuccess } from '@zz2/zz2-ui';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import { IUser } from '../../@types/model/auth/user/user';
import { getMasterDataLastSyncDateLocalStorage,
    getUserSelectedDateFormatLocalStorage,
    getUserSelectedDepartmentsLocalStorage,
    getUserSelectedDivisionsLocalStorage,
    setUserSelectedDateFormatLocalStorage,
    setUserSelectedDepartmentsLocalStorage,
    setUserSelectedDivisionsLocalStorage } from '../../service/localStorageService';
import moment from 'moment';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import GeneralThunk from '../../store/general/thunk';
import { OptionType } from '../../@types/model/optionType';
import { IOptionType } from '@zz2/zz2-ui';
import DataActions from '../../store/masterData/actions';
import MasterDataThunk from '../../store/masterData/thunk';
import { useLocation } from 'react-router-dom';
import RightActions from '../../store/right/actions';
import AuthThunk from '../../store/auth/thunk';
import { ClearIcon } from '@mui/x-date-pickers';
import { GoogleOAuthProvider } from '@react-oauth/google';

interface IUserSettingsProps {
    isOpen : boolean;
    setIsUserDialogOpenCallback : (state : boolean) => void;
    currentUser : IUser | null;
    onClose : () => void;
    signOut : () => void;
}

const UserSettings = (props : IUserSettingsProps) : React.ReactElement => {
    const path = useLocation().pathname;
    const dispatch = useAppDispatch();
    const isMasterDataLoading = useAppSelector(x => x.masterData.isLoading);
    const isMasterDataSyncRunning = useAppSelector(x => x.masterData.masterDataSyncIsRunning);
    const isRightsLoading = useAppSelector(x => x.right.isLoading);
    const divisions = useAppSelector(x => x.masterData.divisionData);
    const departments = useAppSelector(x => x.masterData.departmentData);

    const selectedDivisions = getUserSelectedDivisionsLocalStorage();
    const selectedDepartments = getUserSelectedDepartmentsLocalStorage();
    const userSelectedDateFormat = getUserSelectedDateFormatLocalStorage();

    const [selectedDivisionOptions, setSelectedDivisionOptions] = useState<Array<IOptionType>>([]);
    const [selectedDepartmentOptions, setSelectedDepartmentOptions] = useState<Array<IOptionType>>([]);

    const [userSettingsChanged, setUserSettingsChanged] = useState<boolean>(false);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
    const [isUnlinkEmailDialogOpen, setIsUnlinkEmailDialogOpen] = useState<boolean>(false);

    const dataFormatOptions : Array<IOptionType> = [
        { label: 'DD/MM/YYYY', value: 1, },
        { label: 'YYYY/MM/DD', value: 2, }
    ];

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    useEffect(() => {
        loadData();
    }, [props.isOpen, divisions, departments]);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    const onGoogleAccountLinkSuccess = async (response : IGoogleLoginSuccess) : Promise<void> => {
        await dispatch(AuthThunk.linkGoogleAccountToUser({
            code: response.code,
        }));
    };

    const onGoogleAccountLinkFailure = (response : IGoogleLoginError) : void => {
        dispatch(GeneralThunk.showErrorSnackbar({
            defaultMessage: response.error ?? 'Google Account linking failed.',
            e: response,
        }));

        dispatch(RightActions.setIsLoading(false));
    };

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const loadData = () : void => {
        if (props.currentUser?.divisionIds && props.currentUser.divisionIds.length > 0) {
            const selectedOptions = divisions?.filter(div => selectedDivisions.some(localSelectedDiv => div.id === localSelectedDiv.value))
                .map(OptionType.fromDivision) ?? [];

            if (selectedOptions.length < 1) return;

            setSelectedDivisionOptions(selectedOptions);
        }

        if (props.currentUser?.departmentIds && props.currentUser.departmentIds.length > 0) {
            const selectedOptions = departments?.filter(dept => selectedDepartments.some(localSelectedDept => dept.id === localSelectedDept.value))
                .map(OptionType.fromDepartment) ?? [];

            if (selectedOptions.length < 1) return;

            setSelectedDepartmentOptions(selectedOptions);
        }
    };

    const onUserSettingsDone = () : void => {
        if (path.toLowerCase().includes('/wizard')) {
            setIsConfirmationDialogOpen(true);
        } else {
            confirmUserSettingsUpdate();
        }
    };

    const confirmUserSettingsUpdate = () : void => {
        if (selectedDivisionOptions.length < 1 || selectedDepartmentOptions.length < 1) {
            dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'Select at least one division and department.' }));
            return;
        }

        dispatch(RightActions.setUserSettingsRefresh(true));

        setUserSelectedDivisionsLocalStorage(selectedDivisionOptions);
        dispatch(DataActions.setSelectedUserDivisionIds(selectedDivisionOptions.map(x => Number(x.value))));
        setUserSelectedDepartmentsLocalStorage(selectedDepartmentOptions);
        dispatch(DataActions.setSelectedUserDepartmentIds(selectedDepartmentOptions.map(x => Number(x.value))));

        setUserSettingsChanged(false);
        setIsConfirmationDialogOpen(false);
        props.onClose();
    };

    const onDialogCancel = () : void => {
        setIsConfirmationDialogOpen(false);
    };

    const closeUserSettings = () : void => {
        props.onClose();
        setUserSettingsChanged(false);
    };

    const openUnlinkEmailDialog = () : void => setIsUnlinkEmailDialogOpen(true);

    const closeUnlinkEmailDialog = () : void => setIsUnlinkEmailDialogOpen(false);

    const onUnlinkEmail = () : void => {
        if (!props.currentUser)
            return;

        dispatch(AuthThunk.unlinkGoogleAccount({ userIds: [props.currentUser.id] }));
        closeUnlinkEmailDialog();
    };

    const onDivisionChange = (selectedOptions : Array<IOptionType>) : void => {
        setSelectedDivisionOptions(selectedOptions);
        setSelectedDepartmentOptions([]);
        setUserSettingsChanged(true);
    };

    const onDepartmentChange = (selectedOptions : Array<IOptionType>) : void => {
        setSelectedDepartmentOptions(selectedOptions);
        setUserSettingsChanged(true);
    };

    const onDivisionSelectAll = () : void => {
        if (divisionOptions.length !== selectedDivisionOptions.length) {
            onDivisionChange(divisionOptions);
        } else {
            onDivisionChange([]);
        }
        setUserSettingsChanged(true);
    };

    const onDepartmentSelectAll = () : void => {
        if (departmentOptions.length !== selectedDepartmentOptions.length) {
            onDepartmentChange(departmentOptions);
        } else {
            onDepartmentChange([]);
        }
        setUserSettingsChanged(true);
    };
    
    const getMasterDataLastSyncDate = () : string => {
        const lastSyncDate : moment.Moment | null = getMasterDataLastSyncDateLocalStorage();

        return lastSyncDate ? moment(lastSyncDate).format('DD/MM/YYYY HH:mm:ss') : '';
    };

    const onMasterDataResyncClick = async () : Promise<void> => {
        props.onClose();
        dispatch(DataActions.setIsMasterDataSyncRunning(true));
        dispatch(GeneralThunk.showInfoSnackbar('Master data sync in progress.'));
        await dispatch(MasterDataThunk.manualSyncAllMasterData());
        dispatch(GeneralThunk.showSuccessSnackbar('Master data sync completed.'));
        dispatch(DataActions.setIsMasterDataSyncRunning(false));
    };

    const onSelectedDateFormatChange = (selectedOption : IOptionType | null) : void => {
        setUserSettingsChanged(true);
        setUserSelectedDateFormatLocalStorage(selectedOption?.label ?? null);
        dispatch(DataActions.setSelectedUserDateFormat(selectedOption?.label ?? null));
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const divisionOptions = useMemo<Array<IOptionType>>(() => {
        if (!divisions || !props.currentUser || !props.currentUser.divisionIds) return [];

        return divisions
            .filter(div => div.isActive && (props.currentUser?.divisionIds?.some(divId => div.id === divId)))
            .map(OptionType.fromDivision);
    }, [divisions, props.currentUser]);

    const departmentOptions = useMemo<Array<IOptionType>>(() => {
        if (!departments || !props.currentUser || !props.currentUser.departmentIds) return [];

        return departments
            .filter(dept => dept.isActive
                && (props.currentUser?.departmentIds?.some(deptId => dept.id === deptId))
                && selectedDivisionOptions.some(div => dept.subdivision?.divisionId === div.value))
            .map(OptionType.fromDepartment);
    }, [departments, props.currentUser, selectedDivisionOptions]);

    const selectedDateFormat = useMemo<IOptionType | null>(() => {
        return dataFormatOptions.find(x => x.label === userSelectedDateFormat) ?? null;
    }, [userSelectedDateFormat]);


    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return (
        <div>
            <ProjectDialog
                title={'User Settings'}
                isOpen={props.isOpen}
                isLoadingCircular={isMasterDataSyncRunning || isMasterDataLoading || isRightsLoading}
                fullWidth
                maxWidth={'xs'}
                onConfirm={onUserSettingsDone}
                disableConfirm={!userSettingsChanged || selectedDivisionOptions.length == 0 || selectedDepartmentOptions.length == 0}
                onClose={closeUserSettings}
                closeToolTip={(selectedDivisionOptions.length < 1 || selectedDepartmentOptions.length < 1) ? 'Please select at least one division and department.' : ''}
                disableClose={selectedDivisionOptions.length < 1 || selectedDepartmentOptions.length < 1}>
                <div className={'fdc h500 flx1 aic m10 pt10'}>
                    <div className={'fdc flx1'}>
                        <div className={'fdr wfill jcfe mb5'}>
                            <PillButton
                                text={(divisionOptions.length > 0 && divisionOptions.length === selectedDivisionOptions.length) ? 'Unselect All' : 'Select All'}
                                className={'h20 w120'}
                                onClick={onDivisionSelectAll}
                                color={'secondary'}
                                size={'small'}
                                disabled={divisionOptions.length < 1} />
                        </div>
                        <MuiAutocompleteSelect
                            isMulti
                            className={'w400 h130 oya'}
                            name={'divisions'}
                            label={'Divisions'}
                            options={divisionOptions}
                            value={selectedDivisionOptions}
                            onChange={onDivisionChange}
                            disableClearable
                        />
                        <div className={'fdr wfill jcfe mt30 mb5'}>
                            <PillButton
                                text={(departmentOptions.length > 0 && departmentOptions.length === selectedDepartmentOptions.length) ? 'Unselect All' : 'Select All'}
                                className={'h20 w120'}
                                onClick={onDepartmentSelectAll}
                                color={'secondary'}
                                size={'small'}
                                disabled={departmentOptions.length < 1} />
                        </div>
                        <MuiAutocompleteSelect
                            isMulti
                            className={'w400 h130 oya'}
                            name={'departments'}
                            label={'Departments'}
                            options={departmentOptions}
                            value={selectedDepartmentOptions}
                            onChange={onDepartmentChange}
                            disableClearable
                        />
                    </div>
                    <MuiAutocompleteSelect
                        className={'w400 mt20'}
                        name={'dateFormat'}
                        label={'Date Format'}
                        options={dataFormatOptions}
                        value={selectedDateFormat}
                        onChange={onSelectedDateFormatChange}
                    />
                    <div className={'fdc mt10 mb10 aic jcc'}>
                        <Typography variant={'body2'}>{`Last sync date: ${getMasterDataLastSyncDate()}`}</Typography>
                        <Tooltip className='aic jcc' title={'Does a full resync of the master data'}>
                            <Button variant={'text'} onClick={onMasterDataResyncClick}>Resync Master Data</Button>
                        </Tooltip>
                    </div>
                    <div className={'fdc aic'}>
                        {
                            (!props.currentUser?.email || !props.currentUser.profilePictureURL)
                                && <div className={'zi1'}> 
                                    <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
                                        <GoogleSignInButton          
                                            onGoogleSignInSuccess={onGoogleAccountLinkSuccess}
                                            onGoogleSignInFailure={onGoogleAccountLinkFailure}   
                                            buttonText={`Link ${(!!props.currentUser?.email && !props.currentUser.profilePictureURL) ? 'Profile Picture' : 'Google Account'}`}   
                                        />
                                    </GoogleOAuthProvider>
                                </div>
                        }
                        {
                            !!props.currentUser?.email
                            && <div className={'fdc aic jcc'}>
                                <div className={'fdr aic jcc'}>
                                    <Typography className={'fdr m10'}>Linked Email:</Typography>
                                    <Typography variant={'body1'} className={'fdr fwb cpd m10'}>{ props.currentUser.email }</Typography>
                                    <CustomTooltip title={'Unlink Email'}>
                                        <IconButton className={'cr'} onClick={openUnlinkEmailDialog}>
                                            <ClearIcon />
                                        </IconButton>
                                    </CustomTooltip>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={'fdr aic pb10 mt30'}>
                        <PillButton
                            className={'h35 w150'}
                            text={'LOGOUT'}
                            color={'secondary'}
                            onClick={props.signOut}
                        />
                    </div>
                </div>
            </ProjectDialog >
            <ConfirmationDialog 
                open={!!isConfirmationDialogOpen}
                title={'Update User Settings?'}
                description={'Modifying these settings will result in a reset of any wizard(s) currently in-progress. Are you sure you want to proceed with these changes?'}
                onAccept={confirmUserSettingsUpdate}
                onClose={onDialogCancel}
                dialogType={'orange'}
            />
            <ConfirmationDialog 
                open={isUnlinkEmailDialogOpen}
                title={'Unlink Email'}
                description={'Are you sure you would like to unlink the email address currently associated with your account?'}
                onAccept={onUnlinkEmail}
                onClose={closeUnlinkEmailDialog}
                dialogType={'orange'}
            />
        </div>
    );
};

export default UserSettings;