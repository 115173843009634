import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { ITariffType } from '../../../@types/model/masterData/tariffType/tariffType';
import { ITariffTypeUpsert } from '../../../@types/model/masterData/tariffType/tariffTypeUpsert';

export default class TariffTypeHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataTariffTypeGetList() : AxiosPromise<Array<ITariffType>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/TariffType/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static tariffTypeUpsert = (tariffTypeUpsert : ITariffTypeUpsert, type : 'Add' | 'Edit') : AxiosPromise<ITariffType> => {
        return axios.post(`${API_URL}/v1/MasterData/TariffType/${type}`, tariffTypeUpsert);
    }

    public static tariffTypeDelete = (id : number) : AxiosPromise<ITariffType> => {
        return axios.delete(`${API_URL}/v1/MasterData/TariffType/Delete`, {
            params: { id },
        });
    }
}