import { createReducer } from '@reduxjs/toolkit';
import PieceWorkTransactionActions from './actions';
import { IPieceWorkTransaction } from '../../@types/model/pieceWorkTransaction/pieceWorkTransaction';


export interface IPieceWorkTransactionState {
    isLoading : boolean;
    transactionList : Array<IPieceWorkTransaction> | null;
}

const initialState = {
    isLoading: false,
    transactionList: null,
};

const pieceWorkReducer = createReducer<IPieceWorkTransactionState>(initialState, (builder) => {
    builder.addCase(PieceWorkTransactionActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(PieceWorkTransactionActions.setPieceworkData, (state, action) => {
        state.transactionList = action.payload;
    });
});

export default pieceWorkReducer;