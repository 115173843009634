import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IScanType } from '../../../@types/model/masterData/scanType/scanType';
import { IScanTypeUpsert } from '../../../@types/model/masterData/scanType/scanTypeUpsert';

export default class ScanTypeHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataScanTypeGetList() : AxiosPromise<Array<IScanType>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/ScanType/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static scanTypeUpsert = (scanTypeUpsert : IScanTypeUpsert, type : 'Add' | 'Edit') : AxiosPromise<IScanType> => {
        return axios.post(`${API_URL}/v1/MasterData/ScanType/${type}`, scanTypeUpsert);
    }

    public static scanTypeDelete = (id : number) : AxiosPromise<IScanType> => {
        return axios.delete(`${API_URL}/v1/MasterData/ScanType/Delete`, {
            params: { id },
        });
    }
}