import * as Yup from 'yup';
import { YupSchema } from '../../yupHelper';
import { IFreshServiceTicketFormValues } from './freshServiceTicketFormValues';
import { IFreshServiceTicketUpsert } from './freshServiceTicketUpsert';
import { FreshServicePriority, FreshServiceStatus } from '../../../appConstants';
import { IUser } from '../auth/user/user';

type YupShape = Record<keyof IFreshServiceTicketFormValues, YupSchema>

export default class FreshServiceTicketModelHelper {
    public static createFormValues = (location : string, currentUser ?: IUser | null, status ?: number | null, priority ?: number | null, description ?: string) : IFreshServiceTicketFormValues => {
        return {
            name: currentUser ? currentUser.name : '',
            email: currentUser?.email ? currentUser.email : '',
            subject: location,
            description: description ? description : '',
            priority: priority ?? FreshServicePriority.Low,
            status: status ?? FreshServiceStatus.Open,
            referencedUser: null,
        };
    };

    public static createUpsert = (freshServiceTicketFormValues : IFreshServiceTicketFormValues, users ?: Array<IUser>) : IFreshServiceTicketUpsert => {
        const referencedUser = users?.find(x => x.id === freshServiceTicketFormValues.referencedUser?.value);
        let newDescription : string = freshServiceTicketFormValues.description;

        if (referencedUser) {  
            // description string needs to be in html for fresh service to format ticket correctly
            newDescription += `<br> <br>
              Manager Name: ${referencedUser.name}<br>
              Employee Number: ${referencedUser.employeeNumber}<br>
              Email: ${referencedUser.email}<br>`;
        }

        return {
            name: freshServiceTicketFormValues.name,
            email: freshServiceTicketFormValues.email,
            subject: freshServiceTicketFormValues.subject,
            description: newDescription,
            status: freshServiceTicketFormValues.status,
            priority: freshServiceTicketFormValues.priority,
        };
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = () : Yup.ObjectSchema<YupShape> => Yup.object<YupShape>({
        subject: Yup.string().required('required'),
        description: Yup.string().required('Cannot be blank'),
        name: Yup.string().required('required'),
        email: Yup.string().required('required'),
        status: Yup.number().nullable(),
        priority: Yup.number().nullable(),
        referencedUser: Yup.object().nullable().required('Please select the person that you report to.'),
    });
}
