import React, { useMemo } from 'react';
import { Form, FormikBag, FormikProps, withFormik } from 'formik';
import { Button } from '@mui/material';
import { IFreshServiceTicketFormValues } from '../../../@types/model/freshService/freshServiceTicketFormValues';
import FreshServiceTicketModelHelper from '../../../@types/model/freshService/freshServiceTicketModelHelper';
import { IUser } from '../../../@types/model/auth/user/user';
import { useAppSelector } from '../../../@types/redux';
import { PillButton, CustomTooltip, FormTextInput, FormMuiAutocompleteSelect, IOptionType } from '@zz2/zz2-ui';

interface ITicketCreationPopupProps {
    initialValues : IFreshServiceTicketFormValues;
    onClose : () => void;
    onSubmit : (values : IFreshServiceTicketFormValues) => Promise<void>;
}

type TicketCreationPopupPropsType = ITicketCreationPopupProps & FormikProps<IFreshServiceTicketFormValues>;

const TicketCreationPopup = (props : TicketCreationPopupPropsType) : React.ReactElement => {
    const userData = useAppSelector<Array<IUser>>(x => x.right.userData);

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const userRefOptions = useMemo<Array<IOptionType>>(() => {
        return userData.filter(x => x.isActive && x.employeeNumber && x.email).map((user) => {
            return {
                label: user.name,
                value: user.id,
            };
        });
    }, [userData]);

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return (
        <Form>
            <div className={'fdc p10 pt15 pb10'}>
                <div className={'fdr wfill'}>
                    <FormTextInput disabled className={'flx1 mt10'} label={'Subject'} name={'subject'}/>
                </div>
                <div className={'fdr wfill'}>
                    <FormTextInput multiline rows={4} className={'flx1 mt5 mb10'} label={'Description'} name={'description'}/>
                </div>
                <div className={'fdr wfill mb10'}>
                    <FormMuiAutocompleteSelect
                        className={'flx1'}
                        label={'Manager'}
                        name={'referencedUser'}
                        placeholder={'Please indicate to whom you report to.'}
                        options={userRefOptions}/>
                </div>
                <div className={'fdr flx1 aic jcfe'}>
                    <Button className={'cpd mr20'} type={'reset'} onClick={props.onClose}>
                        CANCEL
                    </Button>
                    <CustomTooltip title={!props.values.referencedUser ? 'You need to indicate to whom you report to.' : ''}>
                        <PillButton
                            className={'pl30 pr30'}
                            text={'SUBMIT'}
                            size={'small'}
                            type={'submit'}
                            color={'secondary'}
                            disabled={!props.values.referencedUser}/>
                    </CustomTooltip>
                </div>
            </div>
        </Form>
    );
};

const handleSubmit = (values : IFreshServiceTicketFormValues, formikBag : FormikBag<ITicketCreationPopupProps, IFreshServiceTicketFormValues>) : void => {
    formikBag.props.onSubmit(values);
};

export default withFormik<ITicketCreationPopupProps, IFreshServiceTicketFormValues>({
    mapPropsToValues: props => props.initialValues,
    handleSubmit,
    enableReinitialize: true,
    validationSchema: FreshServiceTicketModelHelper.formSchema,
})(TicketCreationPopup);
