import lodash from 'lodash';

export default class ArrayHelper {
    /**
     * Sets the value at the specified index of an array without performing mutation i.e. a new array is returned with the
     * change effected.
     * @param arr
     * @param index
     * @param value
     */
    public static setElement = <T>(arr : null | Array<T>, index : number, value : T) : Array<T> => {
        if (arr === null) return [];
        if (index === -1) return ArrayHelper.addElement(arr, value);

        return Object.assign([...arr], { [index]: value });
    };

    /**
     * Adds a new value to an array without performing mutation i.e. a new array is returned with the change effected.
     * @param {Array<T> | null} arr
     * @param {T} value
     * @param {"start" | "end"} position
     * @returns {Array<T>}
     */
    public static addElement = <T>(arr : null | Array<T>, value : T, position : 'start' | 'end' = 'start') : Array<T> => {
        if (arr === null) return [];

        if (position === 'start') {
            return [value, ...arr];
        } else {
            return [...arr, value];
        }
    };

    /**
     * Inserts value if not currently in the provided array, otherwise updates the existing value, by finding the first
     * element for which the equals comparator passes.
     * @param arr
     * @param value
     * @param equals
     * @param position
     */
    public static upsertElement = <T extends {[key : string] : any}>(arr : null | Array<T>, value : T, equals : (a : T) => boolean, position : 'start' | 'end' = 'start') : null | Array<T> => {
        if (arr === null) return null;

        const index = arr.findIndex(equals);
        if (index > -1) {
            return ArrayHelper.setElement(arr, index, value);
        } else {
            return ArrayHelper.addElement(arr, value, position);
        }
    };

    /**
     * Inserts values if not currently in the provided array, otherwise updates the existing values, by finding the first
     * element for which the equals comparator passes.
     * @param arr
     * @param values
     * @param equals
     * @param position
     */
    public static upsertElements = <T extends {[key : string] : any}>(arr : null | Array<T>, values : Array<T>, equals : (a : T, b : T) => boolean) : null | Array<T> => {
        if (arr === null) return null;
        if (!values.length) return arr;

        const newArr = [...arr];

        values.forEach((value) => {
            const index = arr.findIndex(x => equals(x, value));
            if (index > 0) {
                newArr[index] = value;
            } else {
                newArr.push(value);
            }
        });

        return newArr;
    };

    /**
     * Removes the value at the specified index of an array without performing mutation i.e. a new array is returned with
     * the change effected.
     * @param arr
     * @param index
     * @param value
     */
    public static removeElement = <T>(arr : null | Array<T>, index : number) : Array<T> => {
        if (arr === null) return [];
        if (index === -1) return arr;

        return [...arr.slice(0, index), ...arr.slice(index + 1)];
    };

    /**
     * @deprecated Use lodash.keyBy instead.
     * @param {Array<V> | null} arr
     * @param {string} key
     * @returns {Record<number, V>}
     */
    public static toRecord = <V extends {[key : string] : any}>(arr : null | Array<V>, key : string) : Record<number, V> => {
        if (!arr) return {};

        const record : Record<number, V> = {};
        arr.forEach((val : V) => {
            if (!val[key]) return {};
            record[val[key]] = val;
        });
        return record;
    };

    /**
     * Merge new data with existing data. Only take the elements that are unique by id, prioritizing the elements in the
     * newElements array.
     *
     * @param {Array<T> | null} newElements The list of new entries that take priority in overwriting entries in the
     * existing list.
     * @param {Array<T> | null} existingElements The existing list.
     * @param {(row: T) => (string | number)} iteratee
     * @returns {Array<T>}
     */
    public static uniqueJoin = <T>(newElements : null | Array<T>, existingElements : null | Array<T>, iteratee : (row : T) => string | number) : Array<T> => {
        if (existingElements && newElements) {
            return lodash.uniqBy([...newElements, ...existingElements], iteratee);
        }

        if (!newElements && existingElements) return existingElements; // If new list is null, return the existing list.
        if (!existingElements && newElements) return newElements; // If existing list is null, return the new list.

        return []; // Both null, return empty result.
    };
}
