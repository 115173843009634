import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IActivityTypeUpsert } from '../../../@types/model/masterData/activityType/activityTypeUpsert';
import { IActivityType } from '../../../@types/model/masterData/activityType/activityType';

export default class ActivityTypeHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataActivityTypeGetList() : AxiosPromise<Array<IActivityType>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/ActivityType/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static activityTypeUpsert = (activityTypeUpsert : IActivityTypeUpsert, type : 'Add' | 'Edit') : AxiosPromise<IActivityType> => {
        return axios.post(`${API_URL}/v1/MasterData/ActivityType/${type}`, activityTypeUpsert);
    }

    public static activityTypeDelete = (activityTypeId : number) : AxiosPromise<IActivityType> => {
        return axios.delete(`${API_URL}/v1/MasterData/ActivityType/Delete`, {
            params: { activityTypeId },
        });
    }
}