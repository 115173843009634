import axios, { AxiosPromise, CancelTokenStatic, Canceler } from 'axios';
import { IPayRun } from '../../../@types/model/masterData/payRun/payRun';
import { IPayRunUpsert } from '../../../@types/model/masterData/payRun/pyaRunUpsert';

export default class PayRunHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static getList() : AxiosPromise<Array<IPayRun>> {
        if (this.cancelGetList) {
            this.cancelGetList();
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/PayRun/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static upsert(data : IPayRunUpsert, type : 'Add' | 'Edit') : AxiosPromise<IPayRun> {
        return axios.post(`${API_URL}/v1/MasterData/PayRun/${type}`, data);
    }

    public static delete = (id : number) : AxiosPromise<null> => {
        return axios.delete(`${API_URL}/v1/PayRun/Delete`, {
            params: { id },
        });
    }
}
