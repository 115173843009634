import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IRole } from '../../../@types/model/auth/role/role';
import { IRoleUpsert } from '../../../@types/model/auth/role/roleUpsert';

export default class RoleHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static roleGetList() : AxiosPromise<Array<IRole>> {
        if (this.cancelGetList) {
            this.cancelGetList();
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/Rights/Role/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static roleUpsert = (roleUpsert : IRoleUpsert, type : 'Add' | 'Edit') : AxiosPromise<IRole> => {
        return axios.post(`${API_URL}/v1/Rights/Role/${type}`, roleUpsert);
    }

    public static roleDelete = (roleId : number) : AxiosPromise<IRole> => {
        return axios.delete(`${API_URL}/v1/Rights/Role/Delete`, {
            params: { roleId },
        });
    }
}
