import { createReducer } from '@reduxjs/toolkit';
import { IDeduction } from '../../@types/model/deductions/deduction';
import DeductionActions from './actions';


export interface IDeductionState {
    isLoading : boolean;
    deductionList : Array<IDeduction> | null;
}

const initialState = {
    isLoading: false,
    deductionList: null,
};

const deductionReducer = createReducer<IDeductionState>(initialState, (builder) => {
    builder.addCase(DeductionActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(DeductionActions.setDeductionData, (state, action) => {
        state.deductionList = action.payload;
    });
    builder.addCase(DeductionActions.reset, () => {
        return initialState;
    });
});

export default deductionReducer;