import React, { useEffect, useMemo, useState } from 'react';
import defaultTheme from './style/materialTheme';
import { useAppDispatch, useAppSelector } from './@types/redux';
import * as localStorageService from './service/localStorageService';
import './style/app.scss';
import { initializeInterceptor } from './service/http';
import { ThemeProvider } from '@mui/material';
import AuthActions from './store/auth/actions';
import GeneralThunk from './store/general/thunk';
import useFontAwesome from './hooks/useFontAwesome';
import SnackbarNotifier from './modules/root/SnackbarNotifier';
import { IUserToken } from './@types/model/auth/userToken/userToken';
import { OptionType } from './@types/model/optionType';
import DataActions from './store/masterData/actions';
import DivDeptDialog from './modules/component/dialog/DivDeptDialog';
import PasswordRecovery from './modules/root/PasswordRecovery';
import { IPasswordRecoveryFormValues } from './@types/model/auth/password/passwordRecoveryFormValues';
import PasswordRecoveryModelHelper from './@types/model/auth/password/passwordRecoveryModelHelper/passwordRecoveryModelHelper';
import { useLocation } from 'react-router-dom';
import { navReplace } from './store/nav/Actions';
import TicketCreationPopup from './modules/component/dialog/TicketCreationPopup';
import FreshServiceActions from './store/freshService/actions';
import { IFreshServiceTicketFormValues } from './@types/model/freshService/freshServiceTicketFormValues';
import FreshServiceTicketModelHelper from './@types/model/freshService/freshServiceTicketModelHelper';
import FreshServiceThunks from './store/freshService/thunk';
import { VERSION } from './version';
import RightThunks from './store/right/thunk';
import AuthThunk from './store/auth/thunk';
import { IUser } from './@types/model/auth/user/user';
import MasterDataThunk from './store/masterData/thunk';
import { ProjectDialog, Loader, Login, IGoogleLoginSuccess, IGoogleLoginError, IOptionType } from '@zz2/zz2-ui';
import EnvHelper from './service/helper/envHelper';
import NavFrame from './modules/root/NavFrame';
import * as WizardService from './service/wizardService';

const App = () : JSX.Element => {
    const env = ENV_NAME === 'production' ? 'E-Wages Website' : 'E-Wages QA Website';

    useFontAwesome();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const selectedDivisions = localStorageService.getUserSelectedDivisionsLocalStorage();
    const selectedDepartments = localStorageService.getUserSelectedDepartmentsLocalStorage();

    const isLoadingSession = useAppSelector(x => x.auth.isLoadingSession);
    const isLoggingIn = useAppSelector<boolean>(x => x.auth.isLoggingIn);
    const isRightLoading = useAppSelector<boolean>(x => x.right.isLoading);
    const userData = useAppSelector<Array<IUser>>(x => x.right.userData);
    const isFreshServiceLoading = useAppSelector<boolean>(x => x.freshService.isLoading);
    const isSupportTicketDialogOpen = useAppSelector<boolean>(x => x.freshService.isSupportTicketDialogOpen);
    const supportTicketTitle = useAppSelector(x => x.freshService.supportTicketTitle);
    const supportTicketDescription = useAppSelector(x => x.freshService.supportTicketDescription);
    const session = useAppSelector<null | IUserToken>(x => x.auth.session);
    const selectedUserDivisionIds = useAppSelector(x => x.masterData.selectedUserDivisionIds) ?? [];
    const selectedUserDepartmentIds = useAppSelector(x => x.masterData.selectedUserDepartmentIds) ?? [];
    const userSelectedDateFormat = useAppSelector(x => x.masterData.selectedUserDateFormat);
    const showRecoveryScreen = location.pathname.includes('/reset-password');

    const [isDivDeptOpen, setDivDeptOpen] = useState<boolean>(false);

    const onUnauthenticated = (error : unknown) : void => {
        initializeInterceptor('', onUnauthenticated, onUnauthorized, onConnectionError);
        dispatch(GeneralThunk.showErrorSnackbar({
            defaultMessage: 'Unauthorized.',
            e: error,
        }));

        dispatch(AuthActions.logout());
        dispatch(DataActions.reset());
        localStorageService.setLocalStorageSession(null);
        navReplace('/login');
    };

    const onUnauthorized = (error : unknown) : void => {
        dispatch(GeneralThunk.showErrorSnackbar({
            defaultMessage: 'Insufficient rights.',
            e: error,
        }));
    };

    const onConnectionError = () : void => {
        dispatch(GeneralThunk.showErrorSnackbar({
            defaultMessage: 'Connection error.',
        }));
    };

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    useEffect(() => {
        dispatch(AuthActions.setLoggingIn(true));
        localStorageService.onSessionChanged(async (user) => {
            if (user) {
                /* Logged In */
                initializeInterceptor(user.token, onUnauthenticated, onUnauthorized, onConnectionError);
                dispatch(AuthActions.setSession(user));
                dispatch(AuthActions.setLoggedIn(true));
                dispatch(AuthActions.setLoggingIn(false));
            } else {
                /* Logged Out or Not yet logged in */
                initializeInterceptor('', onUnauthenticated, onUnauthorized, onConnectionError);
                dispatch(AuthActions.setLoggedIn(false));
                dispatch(AuthActions.setSession(null));
                dispatch(AuthActions.setLoggingIn(false));
            }
        });

    }, []);

    useEffect(() => {
        if (session?.user.divisionIds !== null && session?.user.divisionIds.length === 1) {
            const selectedDivisions : Array<IOptionType> = session.user.userDivisions
                .filter(x => x.isActive && x.division.isActive)
                .map(x => OptionType.fromDivision(x.division));
            localStorageService.setUserSelectedDivisionsLocalStorage(selectedDivisions);
            dispatch(DataActions.setSelectedUserDivisionIds(session.user.divisionIds));
        }
        if (session?.user.departmentIds !== null && session?.user.departmentIds.length === 1) {
            const selectedDepartments = session.user.userDepartments
                .filter(x => x.isActive && x.department.isActive)
                .map(x => OptionType.fromDepartment(x.department));
            localStorageService.setUserSelectedDepartmentsLocalStorage(selectedDepartments);
            dispatch(DataActions.setSelectedUserDepartmentIds(session.user.departmentIds));
        }
    }, [session, session?.user, session?.user.divisionIds, session?.user.departmentIds]);

    useEffect(() => {
        if (!session) return;

        if (((selectedDivisions.length <= 0) || (selectedDepartments.length <= 0))
            && ((selectedUserDivisionIds.length <= 0) || (selectedUserDepartmentIds.length <= 0)))
        {
            setDivDeptOpen(true);
        }
    }, [selectedUserDivisionIds, selectedUserDepartmentIds, session, selectedDivisions, selectedDepartments]);

    useEffect(() => {
        if (!isSupportTicketDialogOpen) return;

        loadUserList();
    }, [isSupportTicketDialogOpen]);

    useEffect(() => {
        if (!session
            || isLoadingSession
            || location.pathname === '/') return;
        dispatch(AuthThunk.getSession());
    }, [session, location]);

    useEffect(() => {
        if (!session
            || isLoadingSession
            || location.pathname === '/') return;

        syncMasterData();
    }, [session]);

    useEffect(() => {
        if (!location.pathname.includes('wizard')) {
            wizardStateCleanUp();
        }
    }, [location.pathname]);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    const wizardStateCleanUp = async () : Promise<void> => {
        localStorageService.setSelectedPaymentTransactionLocalStorage(null);
        await WizardService.clearProcessWizardIndexedDBObjectStores();
        await WizardService.setProcessWizardSummaryBarStateIndexedDB(null);
    };

    const manualLogin = async (emailOrUsername : string, password : string) : Promise<void> => {
        const res = await dispatch(AuthThunk.manualLogIn({ username: emailOrUsername, password })).unwrap();

        if (res) {
            navReplace('/home');
        }
    };

    const manualSignUp = async (employeeNumber : string, password : string) : Promise<void> => {
        dispatch(AuthThunk.signUp({ employeeNumber, password }));
    };

    const onGoogleLogInSuccess = async (response : IGoogleLoginSuccess) : Promise<void> => {
        const res = await dispatch(AuthThunk.googleLogIn({
            code: response.code,
        })).unwrap();

        if (res) {
            navReplace('/home');
        }
    };

    const onGoogleLogInFailure = (response : IGoogleLoginError) : void => {
        dispatch(GeneralThunk.showErrorSnackbar({
            defaultMessage: response.error ?? 'Login Error',
            e: response,
        }));
        dispatch(AuthActions.setLoggingIn(false));
    };

    const syncMasterData = async () : Promise<void> => {
        dispatch(DataActions.setIsMasterDataSyncRunning(true));
        dispatch(GeneralThunk.showInfoSnackbar('Master data sync in progress.'));
        await dispatch(MasterDataThunk.syncMasterData());
        dispatch(GeneralThunk.showSuccessSnackbar('Master data sync completed.'));
        dispatch(DataActions.setIsMasterDataSyncRunning(false));
    };

    const loadUserList = () : void => {
        dispatch(RightThunks.getUserList({ ignoreRights: true, isActive: true }));
    };

    const onTicketSubmit = async (values : IFreshServiceTicketFormValues) : Promise<void> => {
        const upsertValues = FreshServiceTicketModelHelper.createUpsert(values, userData);
        const res = await dispatch(FreshServiceThunks.createFreshServiceTicket(upsertValues)).unwrap();
        
        if (res) {
            closeSupportTicketDialog();
        }
    };

    const requestForgottenPassword = async (recoveryEmailOrUsername : string) : Promise<void> => {
        // setLoading(true);

        await dispatch(AuthThunk.requestForgottenPassword({ emailOrUsername: recoveryEmailOrUsername }));
        // setShowForgottenPasswordDialog(false);
        // setLoading(false);
    };

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const closeDivDept = () : void => setDivDeptOpen(false);

    const closeSupportTicketDialog = () : void => {
        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(false));
        dispatch(FreshServiceActions.setSupportTicketTitle(''));
        dispatch(FreshServiceActions.setSupportTicketDescription(''));
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const getInitialFormValues = useMemo<IPasswordRecoveryFormValues>(() => {
        return PasswordRecoveryModelHelper.createFormValues();
    }, []);

    const getSupportTicketInitialFormValues = useMemo(() => {
        const regex = new RegExp('/', 'g');
        const formattedLocation = supportTicketTitle !== '' ? supportTicketTitle : (env + (location.pathname === '/' ? '/home' : location.pathname) + '/' + VERSION.version).replace(regex, ' - ');

        return FreshServiceTicketModelHelper.createFormValues(formattedLocation, session?.user, null, null, supportTicketDescription);
    }, [session?.user, location.pathname, supportTicketTitle, supportTicketDescription]);

    const renderApp = useMemo(() => {
        if (isLoggingIn) {
            return <Loader/>;
        }
        
        if (session) {
            return (
                <NavFrame />
            );
        }

        return showRecoveryScreen 
            ? <PasswordRecovery initialFormValues={getInitialFormValues}/>
            : <Login
                env={EnvHelper.getEnvName()}
                version={VERSION.version}
                isLoading={isRightLoading}
                isLoggingIn={isLoggingIn}
                manualLogIn={manualLogin}
                requestForgottenPassword={requestForgottenPassword}
                onGoogleSignInSuccess={onGoogleLogInSuccess}
                onGoogleSignInFailure={onGoogleLogInFailure}
                enableSignUp={true}
                manualSignUp={manualSignUp}
            />;

    }, [isLoggingIn, session, showRecoveryScreen, userSelectedDateFormat]);

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return (
        <ThemeProvider theme={defaultTheme}>
            {renderApp}
            <SnackbarNotifier />
            { 
                !!session &&
                <DivDeptDialog isOpen={isDivDeptOpen} onClose={closeDivDept}/>
            }
            {!!session && isSupportTicketDialogOpen &&
                <ProjectDialog
                    title={'ZZ2FreshService Support Ticket'}
                    isLoadingCircular={isFreshServiceLoading || isRightLoading}
                    isOpen={isSupportTicketDialogOpen}
                    fullWidth
                    maxWidth={'md'}
                    onClose={closeSupportTicketDialog}>
                    <TicketCreationPopup initialValues={getSupportTicketInitialFormValues} onSubmit={onTicketSubmit} onClose={closeSupportTicketDialog}/>
                </ProjectDialog >
            }
        </ThemeProvider>
    );
};

export default App;
