import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '../../service/helper/reduxHelper';
import { IBatchAudit } from '../../@types/model/batch/batchAudit';
import { IBatch } from '../../@types/model/batch/batch';

export default class BatchTransactionActions {
    public static setIsLoading = createAction('BATCH_TRANSACTION_SET_LOADING', withPayloadType<boolean>());
    public static setBatchTransactionData = createAction('BATCH_TRANSACTION_SET_LIST', withPayloadType<Array<IBatch> | null>());
    public static setBatchTransactionAuditData = createAction('BATCH_TRANSACTION_AUDIT_LOG_SET_LIST', withPayloadType<Array<IBatchAudit> | null>());
    public static reset = createAction('BATCH_TRANSACTION_RESET');
}
