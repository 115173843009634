import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { ITariffCalculation } from '../../../@types/model/masterData/tariffCalculation/tariffCalculation';
import { ITariffCalculationUpsert } from '../../../@types/model/masterData/tariffCalculation/tariffCalculationUpsert';

export default class TariffCalculationHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataTariffCalculationGetList() : AxiosPromise<Array<ITariffCalculation>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/TariffCalculation/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static tariffCalculationUpsert = (tariffCalculationUpsert : ITariffCalculationUpsert, type : 'Add' | 'Edit') : AxiosPromise<ITariffCalculation> => {
        return axios.post(`${API_URL}/v1/MasterData/TariffCalculation/${type}`, tariffCalculationUpsert);
    }

    public static tariffCalculationDelete = (id : number) : AxiosPromise<ITariffCalculation> => {
        return axios.delete(`${API_URL}/v1/MasterData/TariffCalculation/Delete`, {
            params: { id },
        });
    }
}