import { OptionsObject } from 'notistack';
import { IAuthState } from '../store/auth/reducer';
import { IGeneralState } from '../store/general/reducer';
import { IRightState } from '../store/right/reducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Draft } from '@reduxjs/toolkit';
import store, { createRootReducer } from '../store';
import { IMasterDataState } from '../store/masterData/reducer';
import { IFreshServiceState } from '../store/freshService/reducer';
import { ILogState } from '../store/logging/reducer';
import { IFrequentlyAskedQuestionState } from '../store/frequentlyAskedQuestions/reducer';
import { ISyncState } from '../store/syncing/reducer';
import { IPaymentTransactionState } from '../store/paymentTransaction/reducer';
import { IPieceWorkTransactionState } from '../store/piecework/reducer';
import { IJobState } from '../store/job/reducer';
import { IRawClockState } from '../store/rawClock/reducer';
import { IDeductionState } from '../store/deductions/reducer';

// eslint-disable-next-line no-unused-vars
export type DispatchCall<P> = (payload : P) => void;
export type DispatchCallEmpty = () => void;

export interface INotification {
    message : string | React.ReactNode;
    options ?: Draft<OptionsObject>;
}

export interface ISnackbarNotification extends INotification {
    key : number;
}

export type RootState = ReturnType<typeof createRootReducer>;

export interface IRootState {
    auth : IAuthState;
    general : IGeneralState;
    right : IRightState;
    masterData : IMasterDataState;
    freshService : IFreshServiceState;
    log : ILogState;
    frequentlyAskedQuestions : IFrequentlyAskedQuestionState;
    sync : ISyncState;
    paymentTransactions : IPaymentTransactionState;
    deductions : IDeductionState;
    jobs : IJobState;
    pieceWorkTransactions : IPieceWorkTransactionState;
    rawClockTransactions : IRawClockState;
}

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector : TypedUseSelectorHook<RootState> = useSelector;

export type ThunkApi = {
    dispatch : AppDispatch;
    state : RootState;
};
