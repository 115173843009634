import * as Yup from 'yup';
import { IPasswordRecoveryFormValues } from '../passwordRecoveryFormValues';
import { YupSchema } from '../../../../yupHelper';

type YupShape = Record<keyof IPasswordRecoveryFormValues, YupSchema>

export default class PasswordRecoveryModelHelper {
    public static createFormValues = () : IPasswordRecoveryFormValues => {
        return {
            password: '',
            confirmPassword: '',
        };
    };

    public static formSchema = Yup.object().shape({
        password: Yup.string().required('Required'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords do not match.').required('Required'),
    });
}