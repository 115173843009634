import React, { useEffect } from 'react';
import { IRootState, ISnackbarNotification, useAppDispatch } from '../../@types/redux';
import { connect } from 'react-redux';
import { OptionsObject, withSnackbar, WithSnackbarProps } from 'notistack';
import generalActions from '../../store/general/actions';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from '@mui/material/Icon/Icon';

interface ISnackbarNotifierProps extends WithSnackbarProps {
    notifications : Array<ISnackbarNotification>;
}

const SnackbarNotifier = (props : ISnackbarNotifierProps) : React.ReactElement => {
    const [displayed, setDisplayed] = React.useState<Array<number>>([]);
    const dispatch = useAppDispatch();
    const onCloseClick = React.useCallback((event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.closeSnackbar(event.currentTarget.value);
    }, []);

    /**
     * When new notifications appear in the redux state, then enqueue the notifications and display them with notiStack.
     */
    useEffect(() => {
        const { notifications = [] } = props;
        notifications.forEach((notification) => {
            // Do nothing if snackbar is already displayed
            if (displayed.includes(notification.key)) return;
            // Display snackbar using notistack
            props.enqueueSnackbar(notification.message, {
                key: `snackbar_${notification.key}`,
                action: <IconButton value={`snackbar_${notification.key}`} onClick={onCloseClick}>
                    <Icon style={{ color: 'white' }}>close</Icon>
                </IconButton>,
                ...notification.options,
            } as OptionsObject);
            // Keep track of snackbars that we've displayed
            setDisplayed([...displayed, notification.key]);
            // Dispatch action to remove snackbar from redux store
            dispatch(generalActions.removeSnackbar(notification.key));
        });

    }, [props.notifications]);
    return (<div className={'fdr'}/>);
};

const mapStateToProps = (state : IRootState) => {
    return {
        notifications: state.general.notifications,
    };
};

export default connect(
    mapStateToProps,
)(withSnackbar(SnackbarNotifier));
