import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IEmployeeRate } from '../../../@types/model/masterData/employeeRate/employeeRate';
import { IEmployeeRateUpsert } from '../../../@types/model/masterData/employeeRate/employeeRateUpsert';

export default class EmployeeRateHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataEmployeeRateGetList() : AxiosPromise<Array<IEmployeeRate>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/EmployeeRate/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static EmployeeRateUpsert = (EmployeeRateUpsert : IEmployeeRateUpsert, type : 'Add' | 'Edit') : AxiosPromise<IEmployeeRate> => {
        return axios.post(`${API_URL}/v1/MasterData/EmployeeRate/${type}`, EmployeeRateUpsert);
    }

    public static EmployeeRateDelete = (id : number) : AxiosPromise<IEmployeeRate> => {
        return axios.delete(`${API_URL}/v1/MasterData/EmployeeRate/Delete`, {
            params: { id },
        });
    }
}