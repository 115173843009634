import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { ICommodity } from '../../../@types/model/masterData/commodity/commodity';
import { ICommodityUpsert } from '../../../@types/model/masterData/commodity/commodityUpsert';

export default class CommodityHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataCommodityGetList() : AxiosPromise<Array<ICommodity>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/Commodity/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static commodityUpsert = (commodityUpsert : ICommodityUpsert, type : 'Add' | 'Edit') : AxiosPromise<ICommodity> => {
        return axios.post(`${API_URL}/v1/MasterData/Commodity/${type}`, commodityUpsert);
    }

    public static commodityDelete = (commodityId : number) : AxiosPromise<ICommodity> => {
        return axios.delete(`${API_URL}/v1/MasterData/Commodity/Delete`, {
            params: { commodityId },
        });
    }
}