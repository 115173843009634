import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '../../service/helper/reduxHelper';
import { ILog } from '../../@types/model/logging/Log';
import { IFileDownloadLog } from '../../@types/model/fileDownloadLogs/fileDownloadLog';

export default class LogActions {
    public static setIsLoading = createAction('LOG_SET_LOADING', withPayloadType<boolean>());
    public static setLogData = createAction('LOG_SET_LOG_DATA', withPayloadType<Array<ILog> | null>());
    public static setFileDownloadLogData = createAction('LOG_SET_FILE_DOWNLOAD_LOG_DATA', withPayloadType<Array<IFileDownloadLog> | null>());
}
