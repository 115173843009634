import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IVariety } from '../../../@types/model/masterData/variety/variety';
import { IVarietyUpsert } from '../../../@types/model/masterData/variety/varietyUpsert';

export default class VarietyHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataVarietyGetList() : AxiosPromise<Array<IVariety>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/Variety/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static varietyUpsert = (varietyUpsert : IVarietyUpsert, type : 'Add' | 'Edit') : AxiosPromise<IVariety> => {
        return axios.post(`${API_URL}/v1/MasterData/Variety/${type}`, varietyUpsert);
    }

    public static varietyDelete = (varietyId : number) : AxiosPromise<IVariety> => {
        return axios.delete(`${API_URL}/v1/MasterData/Variety/Delete`, {
            params: { varietyId },
        });
    }
}