
import GeneralThunk from '../general/thunk';
import { createAsyncThunk } from '@reduxjs/toolkit';
import FreshServiceHttpService from '../../service/http/freshService/freshServiceHttpService';
import FreshServiceActions from './actions';
import { ThunkApi } from '../../@types/redux';
import { IFreshServiceTicketUpsert } from '../../@types/model/freshService/freshServiceTicketUpsert';

export default class FreshServiceThunks {
    /**
     * Submits a ticket to the ZZFreshService, returning the response status
     *
     * @param {IFreshServiceTicketFormValues} freshTicketUpsert
     * @returns {boolean}
     */
    public static createFreshServiceTicket = createAsyncThunk<
        boolean,
        IFreshServiceTicketUpsert,
        ThunkApi>(
            'FRESH_SERVICE_UPSERT_TICKET',
            async (freshTicketUpsert, thunkApi) => {
                try {
                    thunkApi.dispatch(FreshServiceActions.setIsLoading(true));

                    await FreshServiceHttpService.freshServiceTicketUpsert(freshTicketUpsert);
                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Ticket submitted successfully.'));
                    return true;
                } catch (e) {
                    if (e.status === 400) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: e.data?.Message, e}));
                    } else {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred submitting the ticket.'}));
                    }
                    return false;
                } finally {
                    thunkApi.dispatch(FreshServiceActions.setIsLoading(false));
                }
            },
        );

}
