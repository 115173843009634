import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { useEffect } from 'react';

const useFontAwesome = () : null => {
    useEffect(() => {
        library.add(fas);
        library.add(far);
        library.add(fab);
    }, []);

    return null;
};

export default useFontAwesome;
